<ng-container *transloco="let t">
  <ng-template #option let-o>
    <div class="flex w-full items-center justify-between truncate">
      <span class="truncate text-sm text-gray-700">{{ o.label }}</span>
      <div class="flex items-center">
        @if (o.lostAccess) {
          <span
            id="warning-icon-lost-access"
            [className]="ICON_WARNING_TRIANGLE + ' text-orange-500 text-lg'"
            matTooltip="{{ t('account-selector.advertising_lost_warning', [this.listMktplLostAccess(o)]) }}"
          ></span>
        }
        @if (o.bidderOn) {
          <IBadge variant="soft" label="{{ t('account-selector.ads_automation') }}" class="ml-1" size="xs" />
        }
      </div>
    </div>
  </ng-template>
  @if (multiSelection()) {
    <IMultiSelect
      class="w-64"
      [options]="accountOptions()"
      by="id"
      [searchAttributes]="['label', 'accountId', 'category']"
      popperWidth="300px"
      withCategories
      searchable
      (selectedChange)="selectGroups($event)"
      [selected]="selectedAccounts()"
      [minSelectedOptions]="1"
      withSelectAll
    >
      <ng-template #labelSlot>
        <div class="flex w-full truncate">
          @if (selectedAccounts().length === 0) {
            <div class="flex flex-1">{{ t("common.none") }}</div>
          } @else if (selectedAccounts().length === 1) {
            <div class="flex flex-1">
              {{ selectedAccounts()[0].label }}
            </div>
          } @else if (selectedAccounts().length === accountOptions().length) {
            <div class="flex flex-1">
              {{ t("account-selector.all_accounts") }}
            </div>
          } @else {
            <div class="flex flex-1">
              {{ t("account-selector.selected_account", { count: selectedAccounts().length }) }}
            </div>
          }
        </div>
      </ng-template>
      <ng-template #optionSlot let-o>
        <ng-container [ngTemplateOutlet]="option" [ngTemplateOutletContext]="{ $implicit: o }"></ng-container>
      </ng-template>
    </IMultiSelect>
  } @else {
    <ISelect
      class="w-64"
      [options]="accountOptions()"
      [selectedValue]="selectedAccount().value"
      (selectedOptionChange)="selectGroup($event)"
      optionAttribute="label"
      [searchAttributes]="['label', 'accountId', 'category']"
      popperWidth="300px"
      withCategories
      searchable
      testid="account-group-selector"
    >
      <ng-template #optionSlot let-o>
        <ng-container [ngTemplateOutlet]="option" [ngTemplateOutletContext]="{ $implicit: o }"></ng-container>
      </ng-template>
    </ISelect>
  }
</ng-container>
