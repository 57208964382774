<div class="line-bar-chart" *transloco="let t">
  <div style="margin-bottom: 15px" class="flex w-full items-center justify-between pl-5">
    <div class="inline-flex items-center">
      <div class="box-title-sm mr-3">{{ t("sov-main-chart.trend_chart") }}</div>
      <app-switch-button
        [options]="['bar', 'line']"
        [selected]="isBarChart ? 'bar' : 'line'"
        [buttonIcons]="['icon-[lucide--chart-column]', 'icon-[lucide--chart-line]']"
        [buttonTooltips]="['bar', 'line']"
        [type]="SwitchButtonType.ICON"
        (buttonClicked)="changeChartType($event === 'bar')"
      />
    </div>
    <div class="flex">
      @if (dateAggregation$ | async; as dateAggregation) {
        <app-date-aggreation-switch-button
          [selected]="dateAggregation"
          (dateAggSelected)="selectAggregation($event)"
        ></app-date-aggreation-switch-button>
      }
    </div>
  </div>

  @if (!loading) {
    <div class="h-full w-full">
      <canvas baseChart [data]="chartData" [options]="lineChartOptions" [type]="isBarChart ? 'bar' : 'line'"></canvas>
    </div>
  } @else {
    <div class="flex h-full items-center justify-center">
      <app-spinner [main]="false" size="s" [display]="true" type="default"></app-spinner>
    </div>
  }
</div>
