@if (wrapped() || isSmallScreen()) {
  <ISelect
    [options]="dropdownOptions"
    [selectedValue]="selected?.value"
    (selectedOptionChange)="toggleMarketplaceOption($event)"
    class="w-52"
    searchable
    popperWidth="230px"
  />
} @else {
  <div class="flex flex-wrap gap-2">
    @for (entry of selection | keyvalue; track entry) {
      <IButton [label]="entry.key" [color]="!entry.value ? 'white' : 'main'" (onClick)="toggleMarketplace(entry.key)" />
    }
  </div>
}
