import { Component, computed, inject, input, output } from "@angular/core";
import { CommonModule, TitleCasePipe } from "@angular/common";
import { ICON_WARNING_TRIANGLE } from "@m19-board/utils/iconsLabels";
import { getVoidOption, IBadgeComponent, IMultiSelectComponent, ISelectComponent, Option } from "@front/m19-ui";
import { MatTooltip } from "@angular/material/tooltip";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { map, startWith } from "rxjs";
import { OrganizationAccountGroupService } from "@front/m19-services";
import { toSignal } from "@angular/core/rxjs-interop";
import { AccountGroup } from "@front/m19-models";
import { AccountState, AccountType } from "@front/m19-api-client";

@Component({
  selector: "account-group-selector",
  standalone: true,
  imports: [
    CommonModule,
    IBadgeComponent,
    ISelectComponent,
    MatTooltip,
    IMultiSelectComponent,
    TranslocoDirective,
    IMultiSelectComponent,
  ],
  templateUrl: "./account-group-selector.component.html",
})
export class AccountGroupSelectorComponent {
  private readonly accountGroupService = inject(OrganizationAccountGroupService);
  private readonly translocoService = inject(TranslocoService);
  private readonly titleCasePipe = new TitleCasePipe();

  accountOptionsFromGroups = toSignal<Option<AccountGroup>[]>(
    this.accountGroupService.allOrganizationAccountGroups$.pipe(
      map((allOrganizations) => {
        if (!allOrganizations) return [];

        return allOrganizations.flatMap((o) => {
          return o.accountGroups.map((a) => {
            return {
              label: a.name,
              value: a,
              category: o.organizationName,
              bidderOn: this.hasAccountsWithBidderOn(a),
              lostAccess: this.hasLostAccess(a),
              accountId: a.resources[0]?.accountId,
              accountGroupId: a.id,
              testid: `account-group-${a.name}`,
            };
          });
        });
      }),
      startWith([]),
    ),
    { requireSync: true },
  );

  selectedAccountGroupId = input.required<number | undefined>();
  withAllOption = input(false);
  selectedAccountGroupIds = input<number[]>([]);
  multiSelection = input(false);
  accountType = input<AccountType | undefined>();
  organizationId = input<number | undefined>(undefined);

  accountOptions = computed<Option<AccountGroup>[]>(() => {
    let options = this.accountOptionsFromGroups();

    if (this.organizationId()) {
      options = options.filter((o) => o.value.organizationId === this.organizationId());
    }

    if (this.accountType()) {
      options = options.filter((o) => o.value.type === this.accountType());
    }

    return this.withAllOption()
      ? [
          {
            value: {
              accountGroupId: 0,
            },
            label: this.titleCasePipe.transform(this.translocoService.translate("common.all_accounts")),
          } as unknown as Option<AccountGroup>,
          ...options,
        ]
      : options;
  });

  selectedAccount = computed(() => {
    const voidOptionWording = this.titleCasePipe.transform(
      this.translocoService.translate(this.withAllOption() ? "common.all_accounts" : "common.none"),
    );
    return (
      this.accountOptions().find((o) => o.value?.id === this.selectedAccountGroupId()) ??
      getVoidOption(voidOptionWording)
    );
  });
  selectedAccounts = computed(() => {
    return this.accountOptions().filter((o) => this.selectedAccountGroupIds().includes(o.value?.id ?? 0));
  });

  selectAccountGroup = output<AccountGroup | undefined>();
  selectAccountGroups = output<AccountGroup[]>();

  selectGroup(option: Option<AccountGroup | undefined>) {
    this.selectAccountGroup.emit(option.value);
  }

  selectGroups(options: Option<AccountGroup | undefined>[]) {
    this.selectAccountGroups.emit(options.map((o) => o.value!));
  }

  listMktplLostAccess(accountGroup: Option<AccountGroup>): string {
    const marketPlacesLost: string[] = [];
    const accountMarketplaces = accountGroup.value.getAccountMarketplaces();
    for (const mktpl of accountMarketplaces) {
      if (mktpl.isValidToken && !mktpl.hasAccessToAdvertising) {
        marketPlacesLost.push(mktpl.marketplace);
      }
    }
    return marketPlacesLost.join(",");
  }

  private hasAccountsWithBidderOn(accountGroup: AccountGroup): boolean {
    return accountGroup.getAccountMarketplaces().filter((am) => am.state === AccountState.BIDDER_ON).length > 0;
  }

  private hasLostAccess(accountGroup: AccountGroup): boolean {
    for (const mktpl of accountGroup.getAccountMarketplaces()) {
      if (mktpl.isValidToken && !mktpl.hasAccessToAdvertising) {
        return true;
      }
    }
    return false;
  }

  protected readonly ICON_WARNING_TRIANGLE = ICON_WARNING_TRIANGLE;
}
