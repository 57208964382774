import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AuthService, DataSet, UserSelectionService } from "@front/m19-services";
import { ChartConfiguration, ChartData } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import { DecimalPipe, formatDate } from "@angular/common";
import { otherBrandColor, SovBrandDateType } from "../../share-of-voice/share-of-voice.component";
import { map } from "rxjs";
import { TranslocoDirective } from "@jsverse/transloco";
import { SwitchButtonComponent } from "@m19-board/shared/switch-button/switch-button.component";
import { MatTooltip } from "@angular/material/tooltip";
import { SpinnerComponent } from "@m19-board/spinner/spinner.component";

enum PieChartDataMode {
  GLOBAL = "GLOBAL",
  SNAPSHOT = "SNAPSHOT",
}

@UntilDestroy()
@Component({
  selector: "app-sov-pie-chart",
  templateUrl: "./sov-pie-chart.component.html",
  styleUrls: ["./sov-pie-chart.component.scss"],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [TranslocoDirective, BaseChartDirective, SwitchButtonComponent, MatTooltip, DecimalPipe, SpinnerComponent],
})
export class SovPieChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  @Input() set hoveredBrand(b: string | undefined) {
    if (!b) {
      // Reset colors
      this.chartData.datasets[0].backgroundColor =
        this.chartData.datasets[0].hoverBackgroundColor =
        this.chartData.datasets[0].hoverBorderColor =
          this.getChartColors();

      this.chart?.update();

      return;
    }

    const brandIndex = this.chartData.labels!.indexOf(b);
    const dataset = this.chartData.datasets[0];

    for (let i = 0; i < dataset.data.length; i++) {
      if (brandIndex === i) continue;
      const background = dataset.backgroundColor as any[];
      (dataset.backgroundColor as any[])[i] = DataSet.addTransparency(background[i], 10);
    }

    this.chart?.update();
  }

  @Input() set brandSelection(s: string[] | undefined) {
    this._brandSelection = s;
    this.updateChart();
  }

  _brandSelection: string[] | undefined;

  @Input() set sovData(d: SovBrandDateType | undefined) {
    this._sovData = d;
    this.updateChart();
  }

  _sovData?: SovBrandDateType;

  @Input() set snapshotDate(d: Date | undefined) {
    this._snapshotDate = d;
    this.updateChart();
  }

  @Input() loading?: boolean;

  _snapshotDate?: Date;

  @Input() brandsColors?: Map<string, string>;

  dateRange: string[] = [];
  dataMode = PieChartDataMode.SNAPSHOT;

  public chartData: ChartData<"doughnut", number[], string> = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: [],
        hoverBorderColor: [],
        borderWidth: 0,
      },
    ],
  };

  readonly chartConfig: ChartConfiguration<"doughnut">["options"] = {
    responsive: true,
    cutout: "60%",
    plugins: {
      datalabels: { display: false },
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.label + ": " + tooltipItem.formattedValue + "%";
          },
        },
      },
    },
  };

  locale?: string;

  constructor(
    private authService: AuthService,
    private userSelectionService: UserSelectionService,
  ) {}

  ngOnInit(): void {
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user: any) => (this.locale = user.locale));

    this.userSelectionService.dateRange$
      .pipe(
        untilDestroyed(this),
        map((range: string[]) => {
          return range.map((d: string) => new Date(d).toDateString());
        }),
      )
      .subscribe((dateRange: string[]) => {
        this.dateRange = dateRange;
      });
  }

  changeDataMode(mode: PieChartDataMode) {
    this.dataMode = mode;
    this.updateChart();
  }

  updateChart() {
    if (!this._brandSelection || !this._snapshotDate || !this._sovData) return;
    this.chartData.datasets[0].data = this.getChartData(this._sovData, this._snapshotDate, this._brandSelection)!;
    this.updateChartColors();
  }

  private updateChartColors() {
    this.chartData.labels = this.getChartLabels(this._brandSelection!);

    // Add chart colors in same order as data, "All other brands" will always be the last
    this.chartData.datasets[0].backgroundColor =
      this.chartData.datasets[0].hoverBackgroundColor =
      this.chartData.datasets[0].hoverBorderColor =
        this.getChartColors();

    this.chart?.update();
  }

  private getChartData(sovByBrandDate: SovBrandDateType, snapshotDate: Date, brandSelection: string[]) {
    if (!brandSelection) return undefined;
    const newData = [];
    let sovSum = 0;

    for (const b of brandSelection) {
      let snapshotSov: number = 0;
      if (this.dataMode === PieChartDataMode.GLOBAL) {
        snapshotSov = this.getAggregatedDataForBrand(sovByBrandDate, b)!;
      } else {
        snapshotSov = sovByBrandDate.get(b)?.get(snapshotDate.toDateString()) ?? 0;
      }
      newData.push(snapshotSov);
      sovSum += snapshotSov;
    }

    newData.push(100 - sovSum);
    return newData;
  }

  // In 'GLOBAL' mode, computes average brand score on selected date range;
  private getAggregatedDataForBrand(sovByBrandDate: SovBrandDateType, brand: string): number | undefined {
    let averageScoreOnPeriod = 0;
    let scoresNb = 0;

    const scores: Map<string, number> | undefined = sovByBrandDate.get(brand);
    if (!scores) return undefined;
    for (const [k, v] of scores) {
      if (new Date(k) < new Date(this.dateRange[0]) || new Date(k) > new Date(this.dateRange[1])) continue;
      averageScoreOnPeriod += v;
      scoresNb += 1;
    }
    return scoresNb > 0 ? averageScoreOnPeriod / scoresNb : 0;
  }

  private getChartLabels(brandSelection: string[]) {
    return brandSelection.concat("Other brands");
  }

  private getChartColors(): string[] | undefined {
    return this.chartData.labels
      ?.filter((l: string) => l !== "Other brands")
      .map((l: string) => this.brandsColors!.get(l)!)
      .concat(otherBrandColor);
  }

  getDateFormatString() {
    const formatObj = new Intl.DateTimeFormat(this.locale).formatToParts(new Date());
    return formatObj
      .map((obj) => {
        switch (obj.type) {
          case "day":
            return "DD";
          case "month":
            return "MM";
          case "year":
            return "YYYY";
          default:
            return obj.value;
        }
      })
      .join("");
  }

  protected readonly formatDate = formatDate;
  protected readonly PieChartDataMode = PieChartDataMode;
}
